<template>
  <div class='index'>
    <div class='logout' v-if='showLogout'>已退出登录<br />请关闭当前页面</div>
    <div v-if='test'>
      <van-field
        v-model='userToken'
        label='患者Token'
        rows='2'
        autosize
        clearable
        type='textarea'
        placeholder='患者Token'
      />
      <van-button type='primary' @click='loginUser'>LoginUser</van-button>
      <van-field
        v-model='doctorToken'
        label='医生Token'
        rows='2'
        autosize
        clearable
        type='textarea'
        placeholder='医生Token'
      />
      <van-button type='primary' @click='loginDoctor'>LoginDoctor</van-button>
      <van-field
        v-model='assistantToken'
        label='助手Token'
        rows='2'
        autosize
        clearable
        type='textarea'
        placeholder='助手Token'
      />
      <van-button type='primary' @click='loginAssistant'
      >LoginAssistant
      </van-button
      >
    </div>
  </div>
</template>

<script>
import { Button, Field, Toast } from 'vant'
import { getWechatCodeUrl, getAppToken } from '@/api/wechat'

export default {
  name: 'login',
  data() {
    return {
      userInfo: this.$store.state.user.userInfo,
      showLogout: false,
      test: false,
      code: '',
      type: '',
      userToken:
        'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxc2ZhMzI0MjQyMzQyNDIzMSNKV1QjUCIsImVuY3J5cHRpb24iOiIxc2ZhMzI0MjQyMzQyNDIzMSNKV1QjUCIsImlhdCI6MTY4MTY5NDU1Nn0.1-odA_cShIRB9mecOD4cOiURlXf9r0RulziDvr2HCGI',
      assistantToken:
        'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJvNW45dDV0QXp6dUI3aUpmRzZtQ0tzMXQyaDhNI0pXVCNIIiwiZW5jcnlwdGlvbiI6Im81bjl0NXRBenp1QjdpSmZHNm1DS3MxdDJoOE0jSldUI0giLCJpYXQiOjE2OTA4NjMzMTJ9.1V-8GjnYmyYOZ9EHa9Ac5_6iFvbFMesj72dxCyD0ACM',
      doctorToken:
        'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJvNW45dDVvVlppTzg3UzZRXzBucEEzSXE5R1Q0I0pXVCNEIiwiZW5jcnlwdGlvbiI6Im81bjl0NW9WWmlPODdTNlFfMG5wQTNJcTlHVDQjSldUI0QiLCJpYXQiOjE2ODQyODkxMjV9.KccNivUzYSw9s13hQZK_tOmkaG4fjxLelYXwny0g6hk'
    }
  },
  components: {
    [Button.name]: Button,
    [Field.name]: Field
  },
  mounted() {
    this.test = false
    this.showLogout = false
    this.code = this.$route.query.code
    this.type = this.$route.query.type
    const userType = this.$store.getters.getUserType
    if (!this.code && this.type) {
      this.$store.dispatch('SET_LOGIN_OUT')
    }
    if (this.code && this.type) {
      this.getToken()
    } else if (!this.type && !userType) {
      this.showLogout = true
    } else if ((this.type == 'test')) {
      this.test = true
    } else {
      this.init()
    }
  },
  methods: {
    init() {
      const that = this
      const token = that.$store.getters.getToken ?? ''
      let type = that.$store.getters.getUserType ?? ''
      if (token && type) {
        that.$store.dispatch('SET_LOGIN_INFO').then(() => {
          type = that.$store.getters.getUserType ?? ''
          const ok = that.$store.getters.getStatus == '01'
          if (ok) {
            if (type == 'D') {
              that.jump('/doctor')
            } else if (type == 'H') {
              that.jump('/assistant')
            } else if (type == 'P') {
              that.jump('/home')
            }
          } else {
            if (type == 'P') {
              that.$router.replace({
                path: '/personalEdit'
              })
            } else {
              that.$router.replace({
                path: '/auth'
              })
            }
          }
        })
      } else {
        this.getWeChatUrl()
      }
    },
    getWeChatUrl() {
      const that = this
      getWechatCodeUrl().then(res => {
        const url = `${res.start}&redirect_uri=${encodeURI(location.href)}${
          res.end
        }`
        location.replace(url)
      })
      // const that = this
      // let res = 'http://epilepsy-test.yihuyihu.com/renren-api/wechat/getWechatCodeUrl?redirectUrl=' + location.href
      // window.location.href = res
    },
    getToken() {
      const that = this
      getAppToken(that.code, that.type).then(res => {
        console.log(res)
        that.$store.dispatch('SET_TEST_USER_TYPE', {
          token: res.appToken,
          type: res.userType
        })
        localStorage.setItem('avatar', res.avatar)
        that.init()
      })
    },
    loginUser() {
      const that = this
      that.$store.dispatch('SET_TEST_USER_TYPE', {
        token: that.userToken,
        type: 'P'
      })
      that.init()
    },
    loginDoctor() {
      const that = this
      this.$store.dispatch('SET_TEST_USER_TYPE', {
        token: that.doctorToken,
        type: 'D'
      })
      that.init()
    },
    loginAssistant() {
      const that = this
      this.$store.dispatch('SET_TEST_USER_TYPE', {
        token: that.assistantToken,
        type: 'H'
      })
      that.init()
    },
    jump(def) {
      const that = this
      that.$router.replace({
        path: that.$route.query.redirect || def
      })
    }
    // 1. 登录后判断用户身份 医生/助手/医患 以及 认证状态
    // 2. 医生/助手 是否已认证
    // 2.1 未认证 进入认证界面
    // 2.2 已认证 进入聊天界面  医生/助手的聊天界面
    // 3. 用户 是否绑定医生
    // 3.1 用户已绑定医生  再看是否已认证
    // 3.1.1 用户已认证 进入 首页
    // 3.1.2 用户未认证 进入信息确认界面
    // 3.2 用户未绑定医生  进入绑定页面 再进去信息确认界面
  }
}
</script>
<style lang='scss' scoped>
.index {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;

  .logout {
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    text-align: center;
    line-height: 26px;
    color: #01beb7;
  }
}
</style>
