import { request } from '@/utils/request'

// 身份验证
export function authUser(data) {
  return request({
    method: 'post',
    url: `/renren-api/wechat/authUser`,
    data
  })
}

// 身份绑定
export function binding(data) {
  return request({
    method: 'post',
    url: '/renren-api/wechat/binding',
    data
  })
}

// 获取token openid
export function getAppToken(code, userType) {
  return request({
    url: '/renren-api/wechat/getAppToken',
    params: {
      code,
      userType
    }
  })
}

// 获取微信授权页地址
export function getWechatCodeUrl() {
  return request({
    url: '/renren-api/wechat/getWechatCodeUrl'
  })
}
