import Vue from 'vue'
import Vuex from 'vuex'
import modules from './modules'

Vue.use(Vuex)

const getters = {
  getUserType: state => state.user.userType,
  getStatus: state => state.user.userInfo.status??'',
  getToken: state => state.user.token
}

export default new Vuex.Store({
  modules,
  getters,
})
