// 用户cookie
const key = 'APPToken'

export function getToken() {
  return localStorage.getItem(key) || ''
}

export function setToken(token) {
  return localStorage.setItem(key, token)
}

const type = 'userType'

export function getUserType() {
  return localStorage.getItem(type) || ''
}

export function setUserType(value) {
  return localStorage.setItem(type, value)
}