const files = require.context('.', false, /\.js$/)
const modules = {}

let Realmodule = {}

files.keys().forEach(key => {
  if (key === './index.js') return
  modules[key.replace(/(\.\/|\.js)/g, '')] = files(key).default
})
Realmodule = Object.assign({}, modules)
export default Realmodule
