import Vue from 'vue'
import VueRouter from 'vue-router'

import LoginView from '../views/login'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    meta: {
      title: '登录',
      all: true
    },
    component: LoginView
  },
  {
    path: '/home',
    name: 'home',
    meta: {
      title: '首页',
      all: true
    },
    component: () => import('@/views/home')
  },
  {
    path: '/archive',
    name: 'archive',
    meta: {
      title: '健康档案',
      all: true
    },
    component: () => import('@/views/archive')
  },
  {
    path: '/archiveInfo',
    name: 'archiveInfo',
    meta: {
      title: '详情',
      all: true
    },
    component: () => import('@/views/archive/info')
  },
  {
    path: '/chat',
    name: 'chat',
    meta: {
      title: '在线咨询',
      all: true
    },
    component: () => import('@/views/chat')
  },
  {
    path: '/apply',
    name: 'apply',
    meta: {
      title: '需求申请',
      all: true
    },
    component: () => import('@/views/apply')
  },
  {
    path: '/applyEdit',
    name: 'applyEdit',
    meta: {
      title: '需求编辑',
      all: true
    },
    component: () => import('@/views/apply/edit')
  },
  {
    path: '/applyInfo',
    name: 'applyInfo',
    meta: {
      title: '需求详情',
      all: true
    },
    component: () => import('@/views/apply/info')
  },
  {
    path: '/applySuccess',
    name: 'applySuccess',
    meta: {
      title: '申请结果',
      all: true
    },
    component: () => import('@/views/apply/success')
  },
  {
    path: '/applyList',
    name: 'applyList',
    meta: {
      title: '需求申请记录',
      all: true
    },
    component: () => import('@/views/apply/list')
  },
  {
    path: '/introduce',
    name: 'introduce',
    meta: {
      title: '癫痫中心',
      all: true
    },
    component: () => import('@/views/introduce')
  },
  {
    path: '/introduceInfo',
    name: 'introduceInfo',
    meta: {
      title: '癫痫中心',
      all: true
    },
    component: () => import('@/views/introduce/info')
  },
  {
    path: '/health',
    name: 'health',
    meta: {
      title: '健康教育',
      all: true
    },
    component: () => import('@/views/health')
  },
  {
    path: '/personal',
    name: 'personal',
    meta: {
      title: '个人中心',
      all: true
    },
    component: () => import('@/views/personal')
  },
  {
    path: '/personalInfo',
    name: 'personalInfo',
    meta: {
      title: '个人信息',
      all: true
    },
    component: () => import('@/views/personal/info')
  },
  {
    path: '/personalEdit',
    name: 'personalEdit',
    meta: {
      title: '信息认证',
      all: true
    },
    component: () => import('@/views/personal/edit')
  },
  {
    path: '/personalDoctor',
    name: 'personalDoctor',
    meta: {
      title: '绑定医生',
      all: true
    },
    component: () => import('@/views/personal/doctor')
  },
  {
    path: '/doctor',
    name: 'doctor',
    meta: {
      title: '我的患者',
      all: true
    },
    component: () => import('@/views/doctor')
  },
  {
    path: '/doctorChat',
    name: 'doctorChat',
    meta: {
      title: '在线咨询',
      all: true
    },
    component: () => import('@/views/doctor/chat')
  },
  {
    path: '/doctorBinding',
    name: 'doctorBinding',
    meta: {
      title: '绑定医生',
      all: true
    },
    component: () => import('@/views/doctor/binding')
  },
  {
    path: '/doctorBindingSuccess',
    name: 'doctorBindingSuccess',
    meta: {
      title: '绑定医生',
      all: true
    },
    component: () => import('@/views/doctor/success')
  },
  {
    path: '/address',
    name: 'address',
    meta: {
      title: '我的收货地址',
      all: true
    },
    component: () => import('@/views/address')
  },
  {
    path: '/addressEdit',
    name: 'addressEdit',
    meta: {
      title: '地址信息',
      all: true
    },
    component: () => import('@/views/address/edit')
  },
  {
    path: '/outburst',
    name: 'outburst',
    meta: {
      title: '发作',
      all: true
    },
    component: () => import('@/views/outburst')
  },
  {
    path: '/outburst_start',
    name: 'outburstSatrt',
    meta: {
      title: '发作',
      all: true
    },
    component: () => import('@/views/outburst/start')
  },
  {
    path: '/outburstInfo',
    name: 'outburstInfo',
    meta: {
      title: '发作',
      all: true
    },
    component: () => import('@/views/outburst/info')
  },
  {
    path: '/assistant',
    name: 'assistant',
    meta: {
      title: '全部患者',
      all: true
    },
    component: () => import('@/views/assistant')
  },
  {
    path: '/assistantChat',
    name: 'assistantChat',
    meta: {
      title: '在线咨询',
      all: true
    },
    component: () => import('@/views/assistant/chat')
  },
  {
    path: '/auth',
    name: 'auth',
    meta: {
      title: '登录',
      all: true
    },
    component: () => import('@/views/auth')
  },
  {
    path: '/pdf',
    name: 'pdf',
    meta: {
      title: '详情',
      all: true
    },
    component: () => import('@/views/pdf')
  },
  {
    path: '/pc',
    name: 'pc',
    meta: {
      title: '文件上传',
      all: true
    },
    component: () => import('@/views/pc')
  },
  {
    path: '/ndt',
    name: 'ndt',
    meta: {
      title: '脑电图预约',
      all: true
    },
    component: () => import('@/views/ndt')
  },
  {
    path: '/hospital',
    name: 'hospital',
    meta: {
      title: '脑电图预约',
      all: true
    },
    component: () => import('@/views/ndt/hospital')
  },
  {
    path: '/appointment',
    name: 'appointment',
    meta: {
      title: '脑电图预约',
      all: true
    },
    component: () => import('@/views/ndt/appointment')
  },
  {
    path: '/ndt_submit',
    name: 'ndt_submit',
    meta: {
      title: '脑电图预约',
      all: true
    },
    component: () => import('@/views/ndt/submit')
  },
  {
    path: '/ndt_result',
    name: 'ndt_result',
    meta: {
      title: '脑电图预约',
      all: true
    },
    component: () => import('@/views/ndt/result')
  },
  {
    path: '/fzzs',
    name: 'fzzs',
    meta: {
      title: '复诊助手',
      all: true
    },
    component: () => import('@/views/fzzs')
  },
  {
    path: '/fzzs_scheme',
    name: 'fzzs_scheme',
    meta: {
      title: '复诊助手',
      all: true
    },
    component: () => import('@/views/fzzs/scheme')
  },
  {
    path: '/fzzs_edit',
    name: 'fzzs_edit',
    meta: {
      title: '复诊助手',
      all: true
    },
    component: () => import('@/views/fzzs/edit')
  },
  {
    path: '/fzzs_info',
    name: 'fzzs_info',
    meta: {
      title: '复诊助手',
      all: true
    },
    component: () => import('@/views/fzzs/info')
  },
  {
    path: '/yyzs',
    name: 'yyzs',
    meta: {
      title: '用药助手',
      all: true
    },
    component: () => import('@/views/yyzs')
  },
  {
    path: '/yyzs_use',
    name: 'yyzs_use',
    meta: {
      title: '用药助手',
      all: true
    },
    component: () => import('@/views/yyzs/use')
  },
  {
    path: '/yyzs_buy',
    name: 'yyzs_buy',
    meta: {
      title: '用药助手',
      all: true
    },
    component: () => import('@/views/yyzs/buy')
  },
  {
    path: '/ndt_step',
    name: 'ndt_step',
    meta: {
      title: '批量设置',
      all: true
    },
    component: () => import('@/views/assistant/step')
  },
]

const router = new VueRouter({
  scrollBehavior: () => ({ y: 0 }),
  mode: 'history',
  routes
})

export default router
