import router from '@/router'
import store from '@/store'
import { Toast, Notify } from 'vant'
import { getToken, getUserType } from './storage'

router.beforeEach((to, from, next) => {
  // 设置标题
  document.title = to.meta.title || '癫痫公益'
  // 根据token判断用户是否登录
  const hasToken = getToken()
  const hasType = getUserType()
  if (hasToken && hasType && store.getters.getStatus) {
    if (to.path == 'doctor' && hasType != 'D') {
      if (hasType == 'P') {
        next('/home')
      } else if (hasType == 'H') {
        next('/assistant')
      }
    } else if (to.path == 'home' && hasType != 'P') {
      if (hasType == 'D') {
        next('/doctor')
      } else if (hasType == 'H') {
        next('/assistant')
      }
    } else if (to.path == 'assistant' && hasType != 'H') {
      if (hasType == 'P') {
        next('/home')
      } else if (hasType == 'D') {
        next('/doctor')
      }
    } else {
      next()
    }
  } else {
    if (
      to.path == '/doctorBinding' ||
      to.path == '/auth' ||
      (to.path == '/personalEdit') | (to.path == '/') | (to.path == '/pc')
    ) {
      next()
    } else if (hasToken && hasType) {
      store
        .dispatch('SET_LOGIN_INFO')
        .then(res => {
          next()
        })
        .catch(e => {
          console.log(e)
        })
    } else {
      next('/')
    }
  }
})
router.afterEach(() => {
  // Toast.clear()
})
