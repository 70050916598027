import { request } from '@/utils/request'

export function login(data) {
  return request({
    url: '/login',
    method: 'post',
    data
  })
}
// 获取用户信息
export function getUserInfo() {
  return request({
    url: '/renren-api/api/user/getUserInfo',
  })
}

// 获取患者用户信息
export function getDiseaseUserInfo(userId) {
  return request({
    url: '/renren-api/api/user/getDiseaseUserInfo',
    params: {
      userId
    }
  })
}

// 获取短信
export function sendSMS(mobile) {
  return request({
    url: '/renren-api/api/user/sendSMS',
    method: 'post',
    data: {mobile},
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    }
  })
}

// 用户认证
export function userAuth(data) {
  return request({
    url: '/renren-api/api/user/userAuth',
    method: 'post',
    data
  })
}

// 修改绑定关系
export function updateBinding(data) {
  return request({
    url: '/renren-api/api/user/updateBinding',
    method: 'post',
    data
  })
}

// /renren-api/api/user/updateUser
// 修改个人信息关系
// appToken
export function updateUser(data) {
  return request({
    url: '/renren-api/api/user/updateUser',
    method: 'post',
    data
  })
}

// /renren-api/api/user/logOut
// 退出
export function logOut(data) {
  return request({
    url: '/renren-api/api/user/logOut',
    method: 'post',
    data
  })
}
