import { getToken, setToken, getUserType, setUserType } from '@/utils/storage'
import { login, getUserInfo } from '@/api/user'

const state = {
  token: getToken(),
  userType: getUserType(),
  userInfo: {
    userType: '', // D:医生 H：助手 P:患者
    status: '', // 状态00:未认证 01:认证
    sex: '', // 性别 M:男 W:女
    name: null, // 姓名
    mobile: '', // 手机号
    bindingName: null, // 绑定名称
    bindingHospital: null, // 绑定医院
    bindingHospitalCode: null, // 医院COde
    bindingCode: null, // 绑定code
    avatar: null, // 头像
    idCard: '',
    appToken: '' // appToken
  }
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_USER_TYPE: (state, type) => {
    state.userType = type
  },
  SET_USER: (state, user) => {
    state.userInfo = user
  }
}

const actions = {
  SET_LOGIN_IN: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      login(payload)
        .then(res => {
          if (res.code === 200) {
            const token = res.data.appToken || ''
            const type = res.data.userType || ''
            commit('SET_TOKEN', token)
            commit('SET_USER_TYPE', type)
            setToken(token)
            setUserType(type)
            resolve(token)
          } else {
            reject(res)
          }
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  SET_LOGIN_INFO: ({ commit }) => {
    return new Promise((resolve, reject) => {
      getUserInfo()
        .then(res => {
          console.log(res)
          const type = res.userType || ''
          commit('SET_USER_TYPE', type)
          setUserType(type)
          commit('SET_USER', res)
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  SET_LOGIN_OUT: ({ commit }) => {
    return new Promise(resolve => {
      commit('SET_USER', {})
      commit('SET_TOKEN', '')
      setToken('')
      commit('SET_USER_TYPE', '')
      setUserType('')
      resolve()
    })
  },
  SET_TEST_USER_TYPE: ({ commit }, { token, type } = data) => {
    commit('SET_TOKEN', token)
    commit('SET_USER_TYPE', type)
    setToken(token)
    setUserType(type)
  }
}
const getters = {}

export default {
  state,
  mutations,
  actions,
  getters
}
