import axios from 'axios'
import { Toast } from 'vant'
import router from '@/router'
import store from '@/store'

// 创建axios实例
const service = axios.create({
  baseURL: window.location.origin,
  timeout: 30000,
})

// 请求拦截器
service.interceptors.request.use(
  config => {
    const token = store.getters.getToken
    const userType = store.getters.getUserType
    if (token) {
      config.headers.appToken = `${token}`
    }
    if(config.url != '/renren-api/api/oss/upload' && config.url != '/renren-api/api/eeg/eegReservationNumBatchSet') {
      config.data = {
        ...config.data,
        ...{
          // reqId: '1.0',
          // version: '1.0.0',
          userType
        }
      }
    } else {
      console.log(config.headers)
    }
    
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  response => {
    const res = response.data
    if (response.status === 200 && res != null && res.code === 0) {
      localStorage.setItem('time', res.date)
      return res.data
    } else {
      return Promise.reject(res)
    }
  },
  error => {
    return Promise.reject(error)
  }
)

export function request(config) {
  if(config.url != '/renren-api/api/msg/queryPageByMessageRoomId' && config.url !='/renren-api/api/oss/upload') {
    Toast.loading()
  }
  return new Promise((resolve, reject) => {
    service.request(config).then(
      res => {
        Toast.clear()
        resolve(res)
      },
      err => {
        Toast.clear()
        switch (err?.code) {
          case 9:
            // token失效
            const hasType = store.getters.getUserType
            router.push({
              path: hasType=='P' ? '/doctorBinding' : '/auth' 
            })
          break
          default:
            Toast(err.msg || err.message)
            break
        }
        reject(err)
      }
    )
  })
}
